import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Heading from "../../ui/Heading";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "../../utils/formatters";
import { EmptyStateCard } from "../../ui/EmptyCard";
import { UsersIcon } from "../../ui/icons";
import { useState, useEffect } from "react";
interface BudtenderPerformanceProps {
  data: Array<{
    budtender_name: string;
    total_sales: number;
    revenue: number;
    average_sale: number;
    profit_margin: number;
  }> | null;
}

export const BudtenderPerformance: React.FC<BudtenderPerformanceProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Responsive chart width calculation
  const getChartWidth = () => {
    if (windowWidth < 768) {
      // mobile
      return windowWidth - 64; // Full width minus padding
    }
    return Math.min(1000, windowWidth - 64); // Desktop max width or window width minus padding
  };

  const chartHeight = 300;

  if (!data) {
    return (
      <EmptyStateCard
        icon={UsersIcon}
        title={t("no_budtender_data")}
        description={t("no_budtender_data_description")}
        buttonText={t("change_timeframe")}
        onClick={() => {}}
      />
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
      <Heading
        title={t("budtender_performance")}
        size="h4"
        className="mb-6 text-gray-900 dark:text-gray-100"
      />

      <div className="space-y-6 md:space-y-8">
        {/* Responsive Chart */}
        <div className="w-full overflow-x-auto">
          <BarChart
            width={getChartWidth()}
            height={chartHeight}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="budtender_name"
              angle={windowWidth < 768 ? -45 : 0}
              textAnchor={windowWidth < 768 ? "end" : "middle"}
              height={windowWidth < 768 ? 80 : 30}
            />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />
            <Bar
              yAxisId="left"
              dataKey="revenue"
              name={t("revenue")}
              fill="#8884d8"
            />
            <Bar
              yAxisId="right"
              dataKey="total_sales"
              name={t("total_sales")}
              fill="#82ca9d"
            />
          </BarChart>
        </div>

        {/* Responsive Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  {t("budtender")}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  {t("total_sales")}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  {t("revenue")}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  {t("average_sale")}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  {t("profit_margin")}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {data.map((budtender) => (
                <tr key={budtender.budtender_name}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {budtender.budtender_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatNumber(budtender.total_sales, 0)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatCurrency(budtender.revenue)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatCurrency(budtender.average_sale)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatPercentage(budtender.profit_margin)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
