import { z } from "zod";
import api from "../../api";
import { Location } from "../../types";
import { useTranslation } from "react-i18next";
import { countries } from "../../utils/countries";
import { usStates } from "../../utils/us-states";
import { Button } from "../../ui";
import FormWrapper from "../../ui/form/FormWrapper";
import TextInput from "../../ui/form/TextInput";
import { SingleSelect } from "../../ui/form/SingleSelect";
import { useWatch } from "react-hook-form";
import { canadianProvinces } from "../../utils/canadian-provinces";
const locationSchema = z.object({
  name: z.string().min(1, "Name is required"),
  website: z.string().url().optional().or(z.literal("")),
  phone: z
    .string()
    .regex(/^\+?[1-9]\d{1,14}$/, "Invalid phone number")
    .optional()
    .or(z.literal("")),
  address: z.string().min(1, "Address is required"),
  city: z.string().min(1, "City is required"),
  state: z.string().min(1, "State is required"),
  zip: z
    .string()
    .regex(/^\d{5}(-\d{4})?$/, "Invalid ZIP code")
    .optional()
    .or(z.literal("")),
  country: z.string().min(1, "Country is required"),
});

type FormValues = z.infer<typeof locationSchema>;

interface LocationFormProps {
  location?: Location;
  onSave?: (location: Location) => void;
  isOnboarding?: boolean;
}

export default function LocationForm({
  location,
  onSave,
  isOnboarding,
}: LocationFormProps) {
  const { t } = useTranslation();
  const defaults = location ?? {};

  return (
    <FormWrapper<FormValues>
      defaultValues={{
        name: location?.name ?? "",
        website: location?.website ?? "",
        phone: location?.phone ?? "",
        address: location?.address ?? "",
        city: location?.city ?? "",
        state: location?.state ?? "",
        zip: location?.zip ?? "",
        country: location?.country ?? "US",
      }}
      onSubmit={async (data, navigate) => {
        const locationData = {
          ...data,
          locale: location?.locale ?? "en",
          timezone: location?.timezone ?? "UTC",
          link_wrap_email: location?.link_wrap_email ?? false,
          link_wrap_push: location?.link_wrap_push ?? false,
        };

        const savedLocation = location?.id
          ? await api.locations.update(location.id, locationData)
          : await api.locations.create(locationData);
        onSave?.(savedLocation);
      }}
      submitLabel={location ? t("save_settings") : t("lets_go")}
    >
      {(form) => {
        const selectedCountry = useWatch({
          control: form.control,
          name: "country",
        });
        const isUSAddress = selectedCountry === "US";

        return (
          <div className="location-form">
            <div className="form-section grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <TextInput.Field
                  form={form}
                  name="name"
                  label={t("name")}
                  required
                />
              </div>

              <TextInput.Field
                form={form}
                name="website"
                placeholder="https://example.com"
              />
              <TextInput.Field
                form={form}
                name="phone"
                label={t("phone")}
                placeholder="+1 (555) 555-5555"
              />

              <div className="col-span-2">
                <TextInput.Field
                  form={form}
                  name="address"
                  label={t("address")}
                />
              </div>

              <div className="col-span-2 grid grid-cols-3 gap-4">
                <TextInput.Field form={form} name="city" label={t("city")} />
                <SingleSelect.Field
                  form={form}
                  name="state"
                  label={t("state")}
                  options={isUSAddress ? usStates : canadianProvinces}
                  disabled={!isUSAddress}
                />
                <TextInput.Field form={form} name="zip" label={t("zip_code")} />
              </div>

              <div className="col-span-2">
                <SingleSelect.Field
                  form={form}
                  name="country"
                  label={t("country")}
                  options={countries}
                />
              </div>
            </div>
          </div>
        );
      }}
    </FormWrapper>
  );
}
