import { useCallback, useContext } from "react";
import { JourneyContext, LocationContext } from "../../contexts";
import { SearchTable, useSearchTableQueryState } from "../../ui/SearchTable";
import api from "../../api";

export default function JourneyUserEntrances() {
  const [location] = useContext(LocationContext);
  const [journey] = useContext(JourneyContext);

  const locationId = location.id;
  const journeyId = journey.id;

  const state = useSearchTableQueryState(
    useCallback(
      async (params) =>
        await api.automations.entrances.search(locationId, journeyId, params),
      [locationId, journeyId]
    )
  );

  return (
    <SearchTable
      {...state}
      columns={[
        {
          key: "user.external_id",
        },
      ]}
    />
  );
}
