import { useCallback, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../api";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";
import PageContent from "../../ui/PageContent";
import { SearchTable, useSearchTableQueryState } from "../../ui/SearchTable";
import { ArchiveIcon, EditIcon, PlusIcon } from "../../ui/icons";
import { JourneyForm } from "./JourneyForm";
import { Menu, MenuItem, Tag } from "../../ui";
import { LocationContext } from "../../contexts";
import { useTranslation } from "react-i18next";

export const JourneyTag = ({ published }: { published: boolean }) => {
  const { t } = useTranslation();
  const variant = published ? "success" : "plain";
  const title = published ? t("published") : t("draft");
  return <Tag variant={variant}>{title}</Tag>;
};

export default function Journeys() {
  const [location] = useContext(LocationContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationState = useLocation().state as { showCreateModal?: boolean };
  const [open, setOpen] = useState<null | "create">(
    locationState?.showCreateModal ? "create" : null
  );
  const state = useSearchTableQueryState(
    useCallback(
      async (params) => await api.automations.search(location.id, params),
      [location.id]
    )
  );

  const handleEditJourney = (id: number) => {
    navigate(id.toString());
  };

  const handleArchiveJourney = async (id: number) => {
    await api.automations.delete(location.id, id);
    await state.reload();
  };

  return (
    <PageContent
      title={t("automations")}
      actions={
        <Button icon={<PlusIcon />} onClick={() => setOpen("create")}>
          {t("create_automation")}
        </Button>
      }
    >
      <SearchTable
        {...state}
        columns={[
          {
            key: "name",
            title: t("name"),
          },
          {
            key: "status",
            title: t("status"),
            cell: ({ item }) => <JourneyTag published={item.published} />,
          },
          {
            key: "usage",
            title: t("usage"),
            cell: ({ item }) => item.stats?.entrance.toLocaleString(),
          },
          {
            key: "created_at",
            title: t("created_at"),
          },
          {
            key: "updated_at",
            title: t("updated_at"),
          },
          {
            key: "options",
            title: t("options"),
            cell: ({ item: { id } }) => (
              <Menu size="small">
                <MenuItem onClick={() => handleEditJourney(id)}>
                  <EditIcon />
                  {t("edit")}
                </MenuItem>
                <MenuItem onClick={async () => await handleArchiveJourney(id)}>
                  <ArchiveIcon />
                  {t("archive")}
                </MenuItem>
              </Menu>
            ),
          },
        ]}
        onSelectRow={(r) => navigate(r.id.toString())}
        enableSearch
        tagEntity="journeys"
      />
      <Modal
        onClose={() => setOpen(null)}
        open={!!open}
        title={t("create_automation")}
      >
        <JourneyForm
          onSaved={(journey) => {
            setOpen(null);
            navigate(journey.id.toString());
          }}
        />
      </Modal>
    </PageContent>
  );
}
