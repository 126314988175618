import { NavLinkProps, useNavigate } from "react-router-dom";
import { PropsWithChildren, ReactNode, useCallback, useContext } from "react";
import { LocationContext } from "../../contexts";
import api from "../../api";
import { useResolver } from "../../hooks";
import { SingleSelect } from "../../ui/form/SingleSelect";
import { checkLocationRole, getRecentLocations } from "../../utils";
import { LocationRole } from "../../types";
import Sidebar from "../../ui/Sidebar";
import { useTranslation } from "react-i18next";

interface SidebarProps {
  links?: Array<
    NavLinkProps & {
      key: string;
      icon: ReactNode;
      minRole?: LocationRole;
    }
  >;
  prepend?: ReactNode;
  append?: ReactNode;
}

export default function LocationSidebar({
  children,
  links,
}: PropsWithChildren<SidebarProps>) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [recents] = useResolver(
    useCallback(async () => {
      const recentIds = getRecentLocations()
        .filter((p) => p.id !== location.id)
        .map((p) => p.id);
      const recents: Array<typeof location> = [];
      if (recentIds.length) {
        const locations = await api.locations
          .search({
            limit: recentIds.length,
            id: recentIds,
          })
          .then((r) => r.results ?? []);
        for (const id of recentIds) {
          const recent = locations.find((p) => p.id === id);
          if (recent) {
            recents.push(recent);
          }
        }
      }
      return [
        location,
        ...recents,
        {
          id: 0,
          name: t("view_all"),
        },
      ];
    }, [location])
  );

  return (
    location && (
      <Sidebar
        links={links
          ?.filter(
            ({ minRole }) =>
              !minRole || checkLocationRole(minRole, location.role)
          )
          .map(({ minRole, ...props }) => props)}
        prepend={
          <SingleSelect
            value={location}
            onChange={(location) => {
              if (location.id === 0) {
                navigate("/organization/locations");
              } else {
                navigate(`/locations/${location.id}`);
              }
            }}
            options={recents ?? []}
            getSelectedOptionDisplay={(p) => (
              <>
                <div className="location-switcher-label">{t("location")}</div>
                <div className="location-switcher-value">{p.name}</div>
              </>
            )}
            hideLabel
            buttonClassName="location-switcher"
            variant="minimal"
          />
        }
      >
        {children}
      </Sidebar>
    )
  );
}
