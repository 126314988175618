import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { useResolver } from "../../hooks";
import { Location } from "../../types";
import Button from "../../ui/Button";
import PageContent from "../../ui/PageContent";
import { PreferencesContext } from "../../ui/PreferencesContext";
import Tile, { TileGrid } from "../../ui/Tile";
import { formatDate, getRecentLocations } from "../../utils";
import logoUrl from "../../assets/smokey_icon.png";
import { PlusIcon } from "../../ui/icons";
import Modal from "../../ui/Modal";
import LocationForm from "./LocationForm";

export function Locations() {
  const navigate = useNavigate();
  const [preferences] = useContext(PreferencesContext);
  const [locations] = useResolver(api.locations.all);
  const recents = useMemo(() => {
    const recents = getRecentLocations();
    if (!locations?.length || !recents.length) return [];
    return recents.reduce<
      Array<{
        location: Location;
        when: number;
      }>
    >((a, { id, when }) => {
      const location = locations.find((p) => p.id === id);
      if (location) {
        a.push({
          when,
          location,
        });
      }
      return a;
    }, []);
  }, [locations]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (locations && !locations.length) {
      navigate("/onboarding");
    }
  }, [locations, navigate]);

  return (
    <PageContent
      title="Locations"
      desc="Each location has its own workspace with its own sets of users, campaigns, and automations."
      actions={
        <Button
          variant="primary"
          icon={<PlusIcon />}
          onClick={() => setOpen(true)}
        >
          {"Create Location"}
        </Button>
      }
    >
      {!!recents?.length && (
        <>
          <h3>Recently Visited</h3>
          <TileGrid>
            {recents.map(({ location, when }) => (
              <Tile
                key={location.id}
                onClick={() => navigate("/locations/" + location.id)}
                title={location.name || "Untitled Location"}
                iconUrl={logoUrl}
              >
                {formatDate(preferences, when)}
              </Tile>
            ))}
          </TileGrid>
        </>
      )}
      <h3>All Locations</h3>
      <TileGrid>
        {locations?.map((location) => (
          <Tile
            key={location.id}
            onClick={() => navigate("/locations/" + location.id)}
            title={location.name}
            iconUrl={logoUrl}
          >
            {formatDate(preferences, location.created_at)}
          </Tile>
        ))}
      </TileGrid>
      <Modal
        open={open}
        onClose={setOpen}
        title="Create Location"
        size="regular"
      >
        <LocationForm
          onSave={(location) => {
            setOpen(false);
            navigate("/locations/" + location.id);
          }}
        />
      </Modal>
    </PageContent>
  );
}
