import { useCallback, useState, useRef, useEffect, useContext } from "react";
import { LocationContext } from "../../contexts";
import PageContent from "../../ui/PageContent";
import Button from "../../ui/Button";
import {
  SendIcon,
  PlusIcon,
  EditIcon,
  TrashIcon,
  MenuIcon,
} from "../../ui/icons";
import { useTranslation } from "react-i18next";
import { InfoTable } from "../../ui/InfoTable";
import { formatDate } from "../../utils";
import { PreferencesContext } from "../../ui/PreferencesContext";
import useAuth from "../../hooks/useAuth";
import "./chatStyles.css";
import {
  getChats,
  getChatMessages,
  sendMessage,
  renameChat,
  deleteChat,
} from "./api";
import Modal from "../../ui/Modal";
import ReactMarkdown from "react-markdown";
import { FaThumbsUp, FaThumbsDown, FaDownload } from "react-icons/fa";

interface ChatMessage {
  message_id: string;
  type: "human" | "ai";
  content: string;
  created_at: string;
  feedback?: "like" | "dislike";
}

interface Chat {
  chat_id: string;
  name: string;
  created_at: string;
}

const TypingIndicator = () => (
  <div className="chat-message bb-sm-bot-message">
    <div className="typing-indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);

// Add loading indicator component
const LoadingSpinner = () => (
  <div className="loading-spinner-container">
    <div className="loading-spinner"></div>
  </div>
);

// Add this helper function to extract image URLs from markdown content
const extractImageUrls = (markdown: string): string[] => {
  const imageRegex = /!\[.*?\]\((.*?)\)/g;
  const urls: string[] = [];
  let match;

  while ((match = imageRegex.exec(markdown)) !== null) {
    if (match[1]) urls.push(match[1]);
  }

  return urls;
};

export default function ChatPage() {
  const { t } = useTranslation();
  const [preferences] = useContext(PreferencesContext);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentChat, setCurrentChat] = useState<Chat | null>(null);
  const [chats, setChats] = useState<Chat[]>([]);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newChatName, setNewChatName] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [feedbackGiven, setFeedbackGiven] = useState<{
    [key: string]: "like" | "dislike" | null;
  }>({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch user chats on component mount
  useEffect(() => {
    const loadChats = async () => {
      try {
        if (!user) return;
        setIsPageLoading(true);
        const token = await user.getIdToken();
        const chatsData = await getChats(token);
        setChats(chatsData);
      } catch (error) {
        console.error("Error loading chats:", error);
      } finally {
        setIsPageLoading(false);
      }
    };

    loadChats();
  }, [user]);

  // Load messages for selected chat
  const loadMessages = useCallback(
    async (chatId: string) => {
      try {
        setIsMessagesLoading(true);
        const token = await user?.getIdToken();
        const messagesData = await getChatMessages(chatId, token);
        setMessages(messagesData);
      } catch (error) {
        console.error("Error loading messages:", error);
      } finally {
        setIsMessagesLoading(false);
      }
    },
    [user]
  );

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Handle chat selection
  const handleChatSelect = async (chat: Chat) => {
    setIsPageLoading(true);
    setCurrentChat(chat);
    await loadMessages(chat.chat_id);
    setIsPageLoading(false);
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  // Handle creating a new chat
  const handleNewChat = () => {
    setCurrentChat(null);
    setMessages([]);
  };

  // Handle renaming a chat
  const handleRenameChat = async () => {
    if (currentChat && newChatName.trim()) {
      try {
        const token = await user?.getIdToken();
        await renameChat(currentChat.chat_id, newChatName, token);
        // Update chat name locally
        setChats((prevChats) =>
          prevChats.map((chat) =>
            chat.chat_id === currentChat.chat_id
              ? { ...chat, name: newChatName }
              : chat
          )
        );
        setIsRenameModalOpen(false);
        setNewChatName("");
      } catch (error) {
        console.error("Error renaming chat:", error);
      }
    }
  };

  // Open rename modal with existing chat name
  const openRenameModal = () => {
    if (currentChat) {
      setNewChatName(currentChat.name);
      setIsRenameModalOpen(true);
    }
  };

  // Handle deleting a chat
  const handleDeleteChat = async () => {
    if (currentChat) {
      if (window.confirm(t("delete_chat_confirmation"))) {
        try {
          const token = await user?.getIdToken();
          await deleteChat(currentChat.chat_id, token);
          // Remove chat from the list
          setChats((prevChats) =>
            prevChats.filter((chat) => chat.chat_id !== currentChat.chat_id)
          );
          // Reset current chat and messages
          setCurrentChat(null);
          setMessages([]);
        } catch (error) {
          console.error("Error deleting chat:", error);
        }
      }
    }
  };

  // Handle sending a message
  const handleSendMessage = async () => {
    if (!inputValue.trim() || isLoading) return;

    const newUserMessage: ChatMessage = {
      message_id: Date.now().toString(),
      type: "human",
      content: inputValue,
      created_at: new Date().toISOString(),
    };

    setMessages((prev) => [...prev, newUserMessage]);
    setInputValue("");
    setIsLoading(true);

    try {
      const token = await user?.getIdToken();
      const response = await sendMessage(
        inputValue,
        "default", // Adjust voiceType as needed
        currentChat?.chat_id || null,
        token
      );

      // If this is a new chat, update the chat list and current chat
      if (!currentChat && response.chat_id) {
        // Fetch chats again to include the new chat
        const chatsData = await getChats(token!);
        setChats(chatsData);
        // Set currentChat to the new chat
        const newChat = chatsData.find(
          (chat: Chat) => chat.chat_id === response.chat_id
        );
        if (newChat) setCurrentChat(newChat);
      }

      const assistantMessage: ChatMessage = {
        message_id: response.id,
        type: "ai",
        content: response.content,
        created_at: new Date().toISOString(),
      };

      setMessages((prev) => [...prev, assistantMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageLoad = (event: any) => {
    event.target.classList.add("loaded");
  };

  // Add this function to toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Add feedback handler
  const handleFeedback = async (
    messageId: string,
    feedbackType: "like" | "dislike"
  ) => {
    try {
      // TODO: Implement API call to save feedback
      setFeedbackGiven((prev) => ({ ...prev, [messageId]: feedbackType }));
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  // Update the handleImageDownload function
  const handleImageDownload = (content: string) => {
    const imageUrls = extractImageUrls(content);

    imageUrls.forEach((url) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/").pop() || "image";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* Modified sidebar condition to show on mobile when open */}
      {(!isMobile || (isMobile && isSidebarOpen)) && (
        <div
          style={{
            width: isMobile ? "100%" : "300px",
            minWidth: isMobile ? "100%" : "300px",
            borderRight: "1px solid var(--color-grey-light)",
            display: "flex",
            flexDirection: "column",
            paddingTop: "20px",
            position: isMobile ? "fixed" : "relative",
            backgroundColor: "var(--color-background)",
            zIndex: 1000,
            height: "100vh",
          }}
        >
          <h2
            style={{
              fontSize: "1.1rem",
              fontWeight: "600",
              marginBottom: "16px",
              textAlign: "center",
              color: "var(--color-text)",
            }}
          >
            {t("recent_chats")}
          </h2>
          <div style={{ padding: "0 16px 16px" }}>
            <Button
              icon={<PlusIcon />}
              onClick={handleNewChat}
              style={{ width: "100%" }}
            >
              {t("new_chat")}
            </Button>
          </div>
          <div style={{ overflowY: "auto", flex: 1 }}>
            {chats.map((chat) => (
              <div
                key={chat.chat_id}
                onClick={() => handleChatSelect(chat)}
                style={{
                  padding: "12px 16px",
                  cursor: "pointer",
                  backgroundColor:
                    currentChat?.chat_id === chat.chat_id
                      ? "var(--color-grey-light)"
                      : "transparent",
                }}
              >
                {chat.name}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Main chat area */}
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <PageContent
          title={currentChat?.name || t("new_chat")}
          desc={
            currentChat?.created_at && (
              <InfoTable
                rows={{
                  [t("created_on")]: formatDate(
                    preferences,
                    currentChat?.created_at || new Date().toISOString()
                  ),
                }}
                direction="horizontal"
              />
            )
          }
          actions={
            <>
              {isMobile && (
                <Button icon={<MenuIcon />} onClick={toggleSidebar}>
                  {t("chats")}
                </Button>
              )}
              {currentChat && (
                <>
                  <Button icon={<EditIcon />} onClick={openRenameModal}>
                    {t("rename")}
                  </Button>
                  <Button
                    icon={<TrashIcon />}
                    onClick={handleDeleteChat}
                    variant="destructive"
                  >
                    {t("delete")}
                  </Button>
                </>
              )}
            </>
          }
        >
          {isPageLoading ? (
            <LoadingSpinner />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 140px)",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  flex: 1,
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  padding: "20px",
                }}
              >
                {isMessagesLoading ? (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                ) : (
                  <>
                    {messages.map((message) => {
                      const isUser = message.type === "human";
                      return (
                        <div
                          key={message.message_id}
                          className={`chat-message ${
                            isUser ? "bb-sm-user-message" : "bb-sm-bot-message"
                          }`}
                        >
                          <div>
                            <ReactMarkdown
                              components={{
                                img: ({ node, ...props }) => (
                                  <img {...props} onLoad={handleImageLoad} />
                                ),
                              }}
                            >
                              {message.content}
                            </ReactMarkdown>
                          </div>
                          {!isUser && (
                            <div className="bb-sm-feedback-buttons">
                              <div className="bb-sm-left-buttons">
                                {extractImageUrls(message.content).length >
                                  0 && (
                                  <button
                                    onClick={() =>
                                      handleImageDownload(message.content)
                                    }
                                    className="bb-sm-feedback-button"
                                  >
                                    <FaDownload size={12} />
                                  </button>
                                )}
                              </div>
                              <div className="bb-sm-right-buttons">
                                <button
                                  onClick={() =>
                                    handleFeedback(message.message_id, "like")
                                  }
                                  className={`bb-sm-feedback-button ${
                                    feedbackGiven[message.message_id] === "like"
                                      ? "bb-sm-feedback-given"
                                      : ""
                                  }`}
                                  disabled={
                                    feedbackGiven[message.message_id] === null
                                  }
                                >
                                  <FaThumbsUp size={12} />
                                </button>
                                <button
                                  onClick={() =>
                                    handleFeedback(
                                      message.message_id,
                                      "dislike"
                                    )
                                  }
                                  className={`bb-sm-feedback-button ${
                                    feedbackGiven[message.message_id] ===
                                    "dislike"
                                      ? "bb-sm-feedback-given"
                                      : ""
                                  }`}
                                  disabled={
                                    feedbackGiven[message.message_id] === null
                                  }
                                >
                                  <FaThumbsDown size={12} />
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {isLoading && <TypingIndicator />}
                    <div ref={messagesEndRef} />
                  </>
                )}
              </div>
              <div
                style={{
                  padding: "20px",
                  borderTop: "1px solid var(--color-grey-light)",
                  display: "flex",
                  gap: "12px",
                }}
              >
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                  placeholder={t("type_message")}
                  style={{
                    flex: 1,
                    padding: "8px 12px",
                    borderRadius: "6px",
                    border: "1px solid var(--color-grey)",
                    fontSize: "14px",
                  }}
                  disabled={isLoading}
                />
                <Button
                  icon={<SendIcon />}
                  onClick={handleSendMessage}
                  disabled={isLoading || !inputValue.trim()}
                >
                  {isLoading ? t("sending") : t("send")}
                </Button>
              </div>
            </div>
          )}
        </PageContent>
      </div>

      {/* Rename Chat Modal */}
      <Modal
        title={t("rename_chat")}
        open={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
      >
        <input
          type="text"
          value={newChatName}
          onChange={(e) => setNewChatName(e.target.value)}
          placeholder={t("enter_chat_name")}
          style={{
            width: "100%",
            padding: "8px",
            marginBottom: "16px",
          }}
        />
        <Button onClick={handleRenameChat}>{t("save")}</Button>
      </Modal>
    </div>
  );
}
