import Axios from "axios";
import { env } from "./config/env";
import {
  Admin,
  AuthMethod,
  Campaign,
  CampaignCreateParams,
  CampaignLaunchParams,
  CampaignUpdateParams,
  CampaignUser,
  Image,
  Journey,
  JourneyEntranceDetail,
  JourneyStepMap,
  JourneyUserStep,
  List,
  ListCreateParams,
  ListUpdateParams,
  Locale,
  Metric,
  Organization,
  OrganizationUpdateParams,
  Location,
  LocationAdmin,
  LocationAdminInviteParams,
  LocationAdminParams,
  LocationApiKey,
  LocationApiKeyParams,
  Provider,
  ProviderCreateParams,
  ProviderMeta,
  ProviderUpdateParams,
  QueueMetric,
  Resource,
  RuleSuggestions,
  SearchParams,
  SearchResult,
  Subscription,
  SubscriptionCreateParams,
  SubscriptionParams,
  SubscriptionUpdateParams,
  Tag,
  Template,
  TemplateCreateParams,
  TemplatePreviewParams,
  TemplateProofParams,
  TemplateUpdateParams,
  User,
  UserEvent,
  UserSubscription,
  POSData,
  Product,
  ProductParams,
  Insight,
  InsightStatus,
} from "./types";
import { get } from "https";

function appendValue(params: URLSearchParams, name: string, value: unknown) {
  if (
    typeof value === "undefined" ||
    value === null ||
    typeof value === "function"
  )
    return;
  if (typeof value === "object") value = JSON.stringify(value);
  params.append(name, value + "");
}

export const client = Axios.create({
  ...env.api,
  withCredentials: true,
  paramsSerializer: (params) => {
    const s = new URLSearchParams();
    for (const [name, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          appendValue(s, name, item);
        }
      } else {
        appendValue(s, name, value);
      }
    }
    return s.toString();
  },
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      api.auth.login();
    }
    throw error;
  }
);

export interface NetworkError {
  response: {
    data: any;
    status: number;
  };
}

type OmitFields =
  | "id"
  | "created_at"
  | "updated_at"
  | "deleted_at"
  | "stats"
  | "stats_at";

export interface EntityApi<T> {
  basePath: string;
  search: (params: Partial<SearchParams>) => Promise<SearchResult<T>>;
  create: (params: Omit<T, OmitFields>) => Promise<T>;
  get: (id: number | string) => Promise<T>;
  update: (id: number | string, params: Omit<T, OmitFields>) => Promise<T>;
  delete: (id: number | string) => Promise<number>;
}

function createEntityPath<T>(basePath: string): EntityApi<T> {
  return {
    basePath,
    search: async (params) =>
      await client
        .get<SearchResult<T>>(basePath, { params })
        .then((r) => r.data),
    create: async (params) =>
      await client.post<T>(basePath, params).then((r) => r.data),
    get: async (id) =>
      await client.get<T>(`${basePath}/${id}`).then((r) => r.data),
    update: async (id, params) =>
      await client.patch<T>(`${basePath}/${id}`, params).then((r) => r.data),
    delete: async (id) =>
      await client.delete<number>(`${basePath}/${id}`).then((r) => r.data),
  };
}

export interface LocationEntityPath<
  T,
  C = Omit<T, OmitFields>,
  U = Omit<T, OmitFields>
> {
  prefix: string;
  search: (
    locationId: number | string,
    params: SearchParams
  ) => Promise<SearchResult<T>>;
  create: (locationId: number | string, params: C) => Promise<T>;
  get: (locationId: number | string, id: number | string) => Promise<T>;
  update: (
    locationId: number | string,
    id: number | string,
    params: U
  ) => Promise<T>;
  delete: (locationId: number | string, id: number | string) => Promise<number>;
}

const locationUrl = (locationId: number | string) =>
  `/admin/locations/${locationId}`;
export const apiUrl = (locationId: number | string, path: string) =>
  `${env.api.baseURL}/admin/locations/${locationId}/${path}`;

function createLocationEntityPath<
  T,
  C = Omit<T, OmitFields>,
  U = Omit<T, OmitFields>
>(prefix: string): LocationEntityPath<T, C, U> {
  return {
    prefix,
    search: async (locationId, params) =>
      await client
        .get<SearchResult<T>>(`${locationUrl(locationId)}/${prefix}`, {
          params,
        })
        .then((r) => r.data),
    create: async (locationId, params) =>
      await client
        .post<T>(`${locationUrl(locationId)}/${prefix}`, params)
        .then((r) => r.data),
    get: async (locationId, entityId) =>
      await client
        .get<T>(`${locationUrl(locationId)}/${prefix}/${entityId}`)
        .then((r) => r.data),
    update: async (locationId, entityId, params) =>
      await client
        .patch<T>(`${locationUrl(locationId)}/${prefix}/${entityId}`, params)
        .then((r) => r.data),
    delete: async (locationId, entityId) =>
      await client
        .delete<number>(`${locationUrl(locationId)}/${prefix}/${entityId}`)
        .then((r) => r.data),
  };
}

const cache: {
  profile: null | Admin;
} = {
  profile: null,
};

const api = {
  baseUrl: env.api.baseURL,
  auth: {
    methods: async () =>
      await client.get<AuthMethod[]>("/auth/methods").then((r) => r.data),
    check: async (method: string, email: string) =>
      await client
        .post<boolean>("/auth/check", { method, email })
        .then((r) => r.data),
    basicAuth: async (email: string, password: string, redirect = "/") => {
      await client.post("/auth/login/basic/callback", { email, password });
      window.location.href = redirect;
    },
    emailAuth: async (email: string, redirect = "/") => {
      await client.post("/auth/login/email", { email, redirect });
    },
    login() {
      window.location.href = `/login?r=${encodeURIComponent(
        window.location.href
      )}`;
    },
    async logout() {
      // Clear all localStorage
      localStorage.clear();

      // Clear all cookies by setting expired date
      const cookies = document.cookie.split(";");
      for (const cookie of cookies) {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      }

      window.location.href = env.api.baseURL + "/auth/logout";
    },
    firebaseAuth: async (idToken: string, redirect = "/") => {
      await client.post("/auth/login/firebase/callback", { idToken });
      window.location.href = redirect;
    },
  },

  profile: {
    get: async () => {
      if (!cache.profile) {
        cache.profile = await client
          .get<Admin>("/admin/profile")
          .then((r) => r.data);
      }
      return cache.profile!;
    },
  },

  admins: createEntityPath<Admin>("/admin/organizations/admins"),

  locations: {
    ...createEntityPath<Location>("/admin/locations"),
    all: async () =>
      await client.get<Location[]>("/admin/locations/all").then((r) => r.data),
    pathSuggestions: async (locationId: number | string) =>
      await client
        .get<RuleSuggestions>(`${locationUrl(locationId)}/data/paths`)
        .then((r) => r.data),
    rebuildPathSuggestions: async (locationId: number | string) =>
      await client
        .post(`${locationUrl(locationId)}/data/paths/sync`)
        .then((r) => r.data),
  },

  apiKeys: createLocationEntityPath<
    LocationApiKey,
    LocationApiKeyParams,
    Omit<LocationApiKeyParams, "scope">
  >("keys"),

  campaigns: {
    ...createLocationEntityPath<
      Campaign,
      CampaignCreateParams,
      CampaignUpdateParams | CampaignLaunchParams
    >("campaigns"),
    users: async (
      locationId: number | string,
      campaignId: number | string,
      params: SearchParams
    ) =>
      await client
        .get<SearchResult<CampaignUser>>(
          `${locationUrl(locationId)}/campaigns/${campaignId}/users`,
          { params }
        )
        .then((r) => r.data),
    duplicate: async (
      locationId: number | string,
      campaignId: number | string
    ) =>
      await client
        .post<Campaign>(
          `${locationUrl(locationId)}/campaigns/${campaignId}/duplicate`
        )
        .then((r) => r.data),
    generateSuggestions: async (locationId: number | string) =>
      await client
        .post<Campaign[]>(
          `/admin/locations/${locationId}/campaigns/suggestions`
        )
        .then((r) => r.data),
  },

  automations: {
    ...createLocationEntityPath<Journey>("automations"),
    steps: {
      get: async (locationId: number | string, journeyId: number | string) =>
        await client
          .get<JourneyStepMap>(
            `/admin/locations/${locationId}/automations/${journeyId}/steps`
          )
          .then((r) => r.data),
      set: async (
        locationId: number | string,
        journeyId: number | string,
        stepData: JourneyStepMap
      ) =>
        await client
          .put<JourneyStepMap>(
            `/admin/locations/${locationId}/automations/${journeyId}/steps`,
            stepData
          )
          .then((r) => r.data),
      searchUsers: async (
        locationId: number | string,
        journeyId: number | string,
        stepId: number | string,
        params: SearchParams
      ) =>
        await client
          .get<SearchResult<JourneyUserStep>>(
            `/admin/locations/${locationId}/automations/${journeyId}/steps/${stepId}/users`,
            { params }
          )
          .then((r) => r.data),
    },
    entrances: {
      search: async (
        locationId: number | string,
        journeyId: number | string,
        params: SearchParams
      ) =>
        await client
          .get<SearchResult<JourneyUserStep>>(
            `/admin/locations/${locationId}/automations/${journeyId}/entrances`,
            { params }
          )
          .then((r) => r.data),
      log: async (locationId: number | string, entranceId: number | string) =>
        await client
          .get<JourneyEntranceDetail>(
            `${locationUrl(locationId)}/automations/entrances/${entranceId}`
          )
          .then((r) => r.data),
    },
    generateSuggestions: async (locationId: number | string) =>
      await client
        .post<Journey[]>(
          `/admin/locations/${locationId}/automations/suggestions`
        )
        .then((r) => r.data),
  },

  templates: {
    ...createLocationEntityPath<
      Template,
      TemplateCreateParams,
      TemplateUpdateParams
    >("templates"),
    preview: async (
      locationId: number | string,
      templateId: number | string,
      params: TemplatePreviewParams
    ) =>
      await client.post(
        `${locationUrl(locationId)}/templates/${templateId}/preview`,
        params
      ),
    proof: async (
      locationId: number | string,
      templateId: number | string,
      params: TemplateProofParams
    ) =>
      await client.post(
        `${locationUrl(locationId)}/templates/${templateId}/proof`,
        params
      ),
  },

  users: {
    ...createLocationEntityPath<User>("users"),
    upload: async (locationId: number | string, file: File) => {
      const formData = new FormData();
      formData.append("users", file);
      await client.post(`${locationUrl(locationId)}/users`, formData);
    },
    lists: async (
      locationId: number | string,
      userId: number | string,
      params: SearchParams
    ) =>
      await client
        .get<SearchResult<List>>(
          `${locationUrl(locationId)}/users/${userId}/lists`,
          { params }
        )
        .then((r) => r.data),
    events: async (
      locationId: number | string,
      userId: number | string,
      params: SearchParams
    ) =>
      await client
        .get<SearchResult<UserEvent>>(
          `${locationUrl(locationId)}/users/${userId}/events`,
          { params }
        )
        .then((r) => r.data),
    subscriptions: async (
      locationId: number | string,
      userId: number | string,
      params: SearchParams
    ) =>
      await client
        .get<SearchResult<UserSubscription>>(
          `${locationUrl(locationId)}/users/${userId}/subscriptions`,
          { params }
        )
        .then((r) => r.data),
    updateSubscriptions: async (
      locationId: number | string,
      userId: number | string,
      subscriptions: SubscriptionParams[]
    ) =>
      await client
        .patch(
          `${locationUrl(locationId)}/users/${userId}/subscriptions`,
          subscriptions
        )
        .then((r) => r.data),

    automations: {
      search: async (
        locationId: number | string,
        userId: number | string,
        params: SearchParams
      ) =>
        await client
          .get<SearchResult<JourneyUserStep>>(
            `${locationUrl(locationId)}/users/${userId}/automations`,
            { params }
          )
          .then((r) => r.data),
    },
  },

  lists: {
    ...createLocationEntityPath<List, ListCreateParams, ListUpdateParams>(
      "lists"
    ),
    users: async (
      locationId: number | string,
      listId: number | string,
      params: SearchParams
    ) =>
      await client
        .get<SearchResult<User>>(
          `${locationUrl(locationId)}/lists/${listId}/users`,
          { params }
        )
        .then((r) => r.data),
    upload: async (
      locationId: number | string,
      listId: number | string,
      file: File
    ) => {
      const formData = new FormData();
      formData.append("file", file);
      await client.post(
        `${locationUrl(locationId)}/lists/${listId}/users`,
        formData
      );
    },
    generateSuggestions: async (locationId: number | string) =>
      await client
        .post<List[]>(`/admin/locations/${locationId}/lists/suggestions`)
        .then((r) => r.data),
  },

  locationAdmins: {
    search: async (locationId: number, params: SearchParams) =>
      await client
        .get<SearchResult<LocationAdmin>>(`${locationUrl(locationId)}/admins`, {
          params,
        })
        .then((r) => r.data),
    add: async (
      locationId: number,
      adminId: number,
      params: LocationAdminParams
    ) =>
      await client
        .put<LocationAdmin>(
          `${locationUrl(locationId)}/admins/${adminId}`,
          params
        )
        .then((r) => r.data),
    invite: async (locationId: number, params: LocationAdminInviteParams) =>
      await client
        .post<LocationAdmin>(`${locationUrl(locationId)}/admins`, params)
        .then((r) => r.data),
    get: async (locationId: number, adminId: number) =>
      await client
        .get<LocationAdmin>(`${locationUrl(locationId)}/admins/${adminId}`)
        .then((r) => r.data),
    remove: async (locationId: number, adminId: number) =>
      await client
        .delete(`${locationUrl(locationId)}/admins/${adminId}`)
        .then((r) => r.data),
  },

  subscriptions: createLocationEntityPath<
    Subscription,
    SubscriptionCreateParams,
    SubscriptionUpdateParams
  >("subscriptions"),

  providers: {
    all: async (locationId: number | string) =>
      await client
        .get<Provider[]>(`${locationUrl(locationId)}/providers/all`)
        .then((r) => r.data),
    search: async (locationId: number | string, params: any) =>
      await client
        .get<SearchResult<Provider>>(`${locationUrl(locationId)}/providers`, {
          params,
        })
        .then((r) => r.data),
    options: async (locationId: number | string) =>
      await client
        .get<ProviderMeta[]>(`${locationUrl(locationId)}/providers/meta`)
        .then((r) => r.data),
    get: async (
      locationId: number | string,
      group: string,
      type: string,
      entityId: number | string
    ) =>
      await client
        .get<Provider>(
          `${locationUrl(locationId)}/providers/${group}/${type}/${entityId}`
        )
        .then((r) => r.data),
    create: async (
      locationId: number | string,
      { group, type, ...provider }: ProviderCreateParams
    ) =>
      await client
        .post<Provider>(
          `${locationUrl(locationId)}/providers/${group}/${type}`,
          provider
        )
        .then((r) => r.data),
    update: async (
      locationId: number | string,
      entityId: number | string,
      { group, type, ...provider }: ProviderUpdateParams
    ) =>
      await client
        .patch<Provider>(
          `${locationUrl(locationId)}/providers/${group}/${type}/${entityId}`,
          provider
        )
        .then((r) => r.data),
  },

  images: {
    ...createLocationEntityPath<Image>("images"),
    create: async (locationId: number | string, image: File) => {
      const formData = new FormData();
      formData.append("image", image);
      await client.post(`${locationUrl(locationId)}/images`, formData);
    },
  },

  resources: {
    all: async (locationId: number | string, type = "font") =>
      await client
        .get<Resource[]>(`${locationUrl(locationId)}/resources?type=${type}`)
        .then((r) => r.data),
    create: async (locationId: number | string, params: Partial<Resource>) =>
      await client
        .post<Resource>(`${locationUrl(locationId)}/resources`, params)
        .then((r) => r.data),
    delete: async (locationId: number | string, id: number) =>
      await client
        .delete<number>(`${locationUrl(locationId)}/resources/${id}`)
        .then((r) => r.data),
  },

  tags: {
    ...createLocationEntityPath<Tag>("tags"),
    used: async (locationId: number | string, entity: string) =>
      await client
        .get<Tag[]>(`${locationUrl(locationId)}/tags/used/${entity}`)
        .then((r) => r.data),
    assign: async (
      locationId: number | string,
      entity: string,
      entityId: number,
      tags: string[]
    ) =>
      await client
        .put<string[]>(`${locationUrl(locationId)}/tags/assign`, {
          entity,
          entityId,
          tags,
        })
        .then((r) => r.data),
    all: async (locationId: number | string) =>
      await client
        .get<Tag[]>(`${locationUrl(locationId)}/tags/all`)
        .then((r) => r.data),
  },

  organizations: {
    get: async () =>
      await client
        .get<Organization>("/admin/organizations")
        .then((r) => r.data),
    update: async (id: number | string, params: OrganizationUpdateParams) =>
      await client
        .patch<Organization>(`/admin/organizations/${id}`, params)
        .then((r) => r.data),
    delete: async () =>
      await client.delete("/admin/organizations").then((r) => r.data),
    metrics: async () =>
      await client
        .get<QueueMetric>("/admin/organizations/performance/queue")
        .then((r) => r.data),
    jobs: async () =>
      await client
        .get<string[]>("/admin/organizations/performance/jobs")
        .then((r) => r.data),
    jobPerformance: async (job: string) =>
      await client
        .get<Metric[]>(`/admin/organizations/performance/jobs/${job}`)
        .then((r) => r.data),
    failed: async () =>
      await client
        .get<any>("/admin/organizations/performance/failed")
        .then((r) => r.data),
  },

  locales: createLocationEntityPath<Locale>("locales"),

  pos: {
    ...createLocationEntityPath<POSData>("sales-data"),
    search: (locationId: number, params: SearchParams) =>
      client
        .get<SearchResult<POSData>>(`/admin/locations/${locationId}/pos/data`, {
          params,
        })
        .then((r) => r.data),
    upload: (locationId: number, formData: FormData) =>
      client.post(`/admin/locations/${locationId}/pos/import`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
  },
  chat: {
    ...createLocationEntityPath<null>("chat-messages"),
  },
  dashboard: {
    get: (locationId: number, params: any) =>
      client
        .get<any>(`/admin/locations/${locationId}/dashboard`, { params })
        .then((r) => r.data),
    generateInsights: (locationId: number) =>
      client
        .post<any>(`/admin/locations/${locationId}/dashboard/insights/generate`)
        .then((r) => r.data),
  },
  products: {
    search: (locationId: number, params?: SearchParams) =>
      client
        .get<SearchResult<Product>>(`/locations/${locationId}/products`, {
          params,
        })
        .then((r) => r.data),

    get: (locationId: number, productId: number) =>
      client
        .get<Product>(`/locations/${locationId}/products/${productId}`)
        .then((r) => r.data),

    patch: (locationId: number, productId: number, data: Partial<Product>) =>
      client
        .patch<Product>(`/locations/${locationId}/products/${productId}`, data)
        .then((r) => r.data),

    batchPatch: (locationId: number, products: ProductParams[]) =>
      client.patch<void>(`/locations/${locationId}/products`, products),

    upload: (locationId: number, formData: FormData) =>
      client.post(`/locations/${locationId}/products/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
  },
  insights: {
    get: async (locationId: number | string) =>
      await client
        .get<Insight[]>(`/admin/locations/${locationId}/dashboard/insights`)
        .then((r) => r.data),
    generate: async (locationId: number | string) =>
      await client
        .post<Insight[]>(
          `/admin/locations/${locationId}/dashboard/insights/generate`
        )
        .then((r) => r.data),
    updateStatus: async (
      locationId: number | string,
      insightId: number,
      status: InsightStatus
    ) =>
      await client
        .patch(`/admin/locations/${locationId}/insights/${insightId}/status`, {
          status,
        })
        .then((r) => r.data),
  },
};

export default api;

(window as any).API = api;
