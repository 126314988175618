import { createContext, useState, useContext, ReactNode } from "react";

interface Chat {
  chat_id: string;
  name: string;
  created_at: string;
}

interface ChatContextType {
  chats: Chat[];
  setChats: (chats: Chat[]) => void;
  currentChat: Chat | null;
  setCurrentChat: (chat: Chat | null) => void;
  handleChatSelect: (chat: Chat) => Promise<void>;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export function ChatProvider({ children }: { children: ReactNode }) {
  const [chats, setChats] = useState<Chat[]>([]);
  const [currentChat, setCurrentChat] = useState<Chat | null>(null);

  const handleChatSelect = async (chat: Chat) => {
    setCurrentChat(chat);
    // We'll implement the message loading logic in ChatPage
  };

  return (
    <ChatContext.Provider
      value={{
        chats,
        setChats,
        currentChat,
        setCurrentChat,
        handleChatSelect,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
