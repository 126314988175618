import { useContext } from "react";
import { LocationContext } from "../../contexts";
import Heading from "../../ui/Heading";
import { toast } from "react-hot-toast/headless";
import LocationForm from "../location/LocationForm";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui";
import api from "../../api";

export default function LocationSettings() {
  const { t } = useTranslation();
  const [location, setLocation] = useContext(LocationContext);

  return (
    <>
      <Heading size="h3" title={t("general")} />
      <LocationForm
        location={location}
        onSave={(location) => {
          setLocation(location);
          toast.success(t("location_settings_saved"));
        }}
      />
    </>
  );
}
