import Button from "./Button";

export const EmptyStateCard = ({
  icon: Icon,
  title,
  description,
  buttonText,
  onClick,
}: {
  icon: React.ComponentType;
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
      gap: "1rem",
      color: "var(--gray-500)",
      minHeight: "200px",
    }}
  >
    <div
      className="text-gray-400"
      style={{
        width: "40px",
        height: "40px",
      }}
    >
      <Icon />
    </div>
    <span className="font-medium text-gray-600 dark:text-gray-300">
      {title}
    </span>
    <p className="text-center text-sm text-gray-500 dark:text-gray-400 max-w-sm">
      {description}
    </p>
    <Button onClick={onClick} variant="secondary" size="small">
      {buttonText}
    </Button>
  </div>
);
