import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import PageContent from "../../ui/PageContent";
import { SearchTable, useSearchTableQueryState } from "../../ui/SearchTable";
import { useRoute } from "../router";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button";
import { UploadIcon } from "../../ui/icons";
import Modal from "../../ui/Modal";
import FormWrapper from "../../ui/form/FormWrapper";
import UploadField from "../../ui/form/UploadField";

export default function Users() {
  const { locationId = "" } = useParams();
  const { t } = useTranslation();
  const route = useRoute();
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const state = useSearchTableQueryState(
    useCallback(
      async (params) => await api.users.search(locationId, params),
      [locationId]
    )
  );

  const uploadUsers = async (file: FileList) => {
    const formData = new FormData();
    formData.append("users", file[0]);

    await api.users.upload(locationId, file[0]);
    setIsUploadOpen(false);
    await state.reload();
  };

  return (
    <>
      <PageContent
        title={t("customers")}
        actions={
          <Button icon={<UploadIcon />} onClick={() => setIsUploadOpen(true)}>
            {t("import_users")}
          </Button>
        }
      >
        <SearchTable
          {...state}
          columns={[
            { key: "full_name", title: t("name"), sortable: true },
            { key: "external_id", title: t("external_id") },
            { key: "email", title: t("email"), sortable: true },
            { key: "phone", title: t("phone") },
            { key: "locale", title: t("language") },
            { key: "created_at", title: t("created_at"), sortable: true },
          ]}
          onSelectRow={({ id }) => route(`users/${id}`)}
          enableSearch
          searchPlaceholder={t("search_users")}
          tagEntity="users"
        />
      </PageContent>

      <Modal
        title={t("import_users")}
        open={isUploadOpen}
        onClose={() => setIsUploadOpen(false)}
      >
        <FormWrapper<{ file: FileList }>
          onSubmit={async (form) => await uploadUsers(form.file)}
          submitLabel={t("upload")}
        >
          {(form) => (
            <>
              <p>{t("upload_instructions")}</p>
              <UploadField form={form} name="file" label={t("file")} required />
            </>
          )}
        </FormWrapper>
      </Modal>
    </>
  );
}
