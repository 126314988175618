import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../contexts";
import { UseFormReturn } from "react-hook-form";
import api from "../../api";
import { toast } from "react-hot-toast/headless";
import Heading from "../../ui/Heading";
import Button from "../../ui/Button";
import FormWrapper from "../../ui/form/FormWrapper";
import TextInput from "../../ui/form/TextInput";
import SwitchField from "../../ui/form/SwitchField";
import { SingleSelect } from "../../ui/form/SingleSelect";
import { LocaleTextField } from "./Locales";
import { Location } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Intl {
  type Key =
    | "calendar"
    | "collation"
    | "currency"
    | "numberingSystem"
    | "timeZone"
    | "unit";
  function supportedValuesOf(input: Key): string[];

  interface DateTimeFormat {
    // eslint-disable-next-line @typescript-eslint/method-signature-style
    format(date?: Date | number): string;
    // eslint-disable-next-line @typescript-eslint/method-signature-style
    resolvedOptions(): ResolvedDateTimeFormatOptions;
  }

  interface ResolvedDateTimeFormatOptions {
    locale: string;
    timeZone: string;
    timeZoneName?: string;
  }

  // eslint-disable-next-line no-var
  var DateTimeFormat: {
    new (locales?: string | string[]): DateTimeFormat;
    (locales?: string | string[]): DateTimeFormat;
  };
}

export default function AdvancedSettings() {
  const { t } = useTranslation();
  const [location, setLocation] = useContext(LocationContext);
  const timeZones = Intl.supportedValuesOf("timeZone");

  return (
    <FormWrapper<Location>
      defaultValues={location}
      onSubmit={async (formData) => {
        const updatedLocation = await api.locations.update(
          formData.id,
          formData
        );
        setLocation(updatedLocation);
        toast.success(t("location_settings_saved"));
      }}
      submitLabel={t("save_settings")}
    >
      {(form: UseFormReturn<Location>) => (
        <div className="form-section">
          <Heading size="h4" title={t("localization")} />
          <div className="form-row">
            <LocaleTextField
              form={form}
              name="locale"
              label={t("default_language")}
              required
            />
            <SingleSelect.Field
              form={form}
              options={timeZones}
              name="timezone"
              label={t("timezone")}
              required
            />
          </div>

          <Heading size="h4" title={t("message_settings")} />
          <TextInput.Field
            form={form}
            name="text_opt_out_message"
            label={t("sms_opt_out_message")}
            subtitle={t("sms_opt_out_message_subtitle")}
          />
          <TextInput.Field
            form={form}
            name="text_help_message"
            label={t("sms_help_message")}
            subtitle={t("sms_help_message_subtitle")}
          />
          <SwitchField
            form={form}
            name="link_wrap_email"
            label={t("link_wrapping_email")}
            subtitle={t("link_wrapping_email_subtitle")}
          />
          <SwitchField
            form={form}
            name="link_wrap_push"
            label={t("link_wrapping_push")}
            subtitle={t("link_wrapping_push_subtitle")}
          />
          {/* 
          <Heading size="h4" title={t("advanced_options")} />
          <br />
          <Button
            variant="secondary"
            onClick={async () => {
              if (location?.id) {
                await api.locations.rebuildPathSuggestions(location.id);
                toast.success(t("rebuild_path_suggestions_success"));
              }
            }}
            disabled={!location?.id}
          >
            {t("rebuild_path_suggestions")}
          </Button>
          <span className="help-text">
            {t("rebuild_path_suggestions_desc")}
          </span> */}
        </div>
      )}
    </FormWrapper>
  );
}
