import { useCallback, useContext } from "react";
import api from "../../api";
import { LocationContext, UserContext } from "../../contexts";
import { SearchParams } from "../../types";
import ListTable from "./ListTable";
import { useTranslation } from "react-i18next";

export default function UserDetailLists() {
  const { t } = useTranslation();
  const [location] = useContext(LocationContext);
  const [user] = useContext(UserContext);
  const search = useCallback(
    async (params: SearchParams) =>
      await api.users.lists(location.id, user.id, params),
    [api.users, location]
  );

  return (
    <>
      <ListTable search={search} title={t("lists")} />
    </>
  );
}
