import { Outlet } from "react-router-dom";
import "./Auth.css";
import logoPng from "../../assets/BakedBot_banner_logo_beta.png";
export default function Onboarding() {
  return (
    <div className="auth onboarding">
      <div className="logo">
        <img src={logoPng} alt="Logo" />
      </div>
      <Outlet />
    </div>
  );
}
