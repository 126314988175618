import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContent, Button, Alert } from "../../ui";
import api from "../../api";
import { Insight, InsightStatus } from "../../types";
import { LocationContext } from "../../contexts";

export default function Insights() {
  const { t } = useTranslation();
  const [insights, setInsights] = useState<Insight[]>([]);
  const [loading, setLoading] = useState(true);
  const [location] = useContext(LocationContext);

  useEffect(() => {
    loadInsights();
  }, []);

  const loadInsights = async () => {
    setLoading(true);
    try {
      const data = await api.insights.get(location.id);
      setInsights(data);
    } catch (error) {
      console.error("Error loading insights:", error);
    }
    setLoading(false);
  };

  const handleStatusUpdate = async (
    insightId: number,
    status: InsightStatus
  ) => {
    await api.insights.updateStatus(location.id, insightId, status);
    await loadInsights();
  };

  return (
    <PageContent
      title={t("ai_insights")}
      actions={
        <Button onClick={() => api.insights.generate(location.id)}>
          {t("generate_insights")}
        </Button>
      }
    >
      <div className="space-y-4">
        {insights.map((insight) => (
          <Alert
            key={insight.id}
            variant={insight.impact === "high" ? "error" : "warn"}
            title={insight.title}
            body={insight.description}
            actions={
              <div className="flex gap-3">
                <Button
                  variant="secondary"
                  size="small"
                  onClick={() => handleStatusUpdate(insight.id, "dismissed")}
                >
                  {t("dismiss")}
                </Button>
                <Button
                  size="small"
                  onClick={() => handleStatusUpdate(insight.id, "completed")}
                >
                  {t("mark_completed")}
                </Button>
              </div>
            }
          >
            <div className="flex gap-2 mt-2">
              <Button
                variant="secondary"
                size="small"
                className="!min-w-0 !p-2"
              >
                {insight.impact}
              </Button>
              <Button
                variant="secondary"
                size="small"
                className="!min-w-0 !p-2"
              >
                {insight.type}
              </Button>
            </div>
          </Alert>
        ))}
      </div>
    </PageContent>
  );
}
