import { useNavigate } from "react-router-dom";
import LocationForm from "../location/LocationForm";
import { useTranslation } from "react-i18next";

export default function OnboardingLocation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="auth-step">
      <h1>{t("onboarding_location_setup_title")}</h1>
      <p>{t("onboarding_location_setup_description")}</p>
      <LocationForm
        isOnboarding={true}
        onSave={({ id }) => navigate("/locations/" + id)}
      />
    </div>
  );
}
