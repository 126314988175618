import {
  createBrowserRouter,
  Outlet,
  redirect,
  RouteObject,
  useNavigate,
  useParams,
} from "react-router-dom";
import api from "../api";

import ErrorPage from "./ErrorPage";
import { SidebarLink } from "../ui/Sidebar";
import {
  LoaderContextProvider,
  StatefulLoaderContextProvider,
} from "./LoaderContextProvider";
import {
  AdminContext,
  CampaignContext,
  JourneyContext,
  ListContext,
  LocationContext,
  UserContext,
} from "../contexts";
import ApiKeys from "./settings/ApiKeys";
import EmailEditor from "./campaign/editor/EmailEditor";
import Lists from "./users/Lists";
import ListDetail from "./users/ListDetail";
import Users from "./users/Users";
import Teams from "./settings/Teams";
import Subscriptions from "./settings/Subscriptions";
import UserDetail from "./users/UserDetail";
import { createStatefulRoute } from "./createStatefulRoute";
import UserDetailAttrs from "./users/UserDetailAttrs";
import UserDetailEvents from "./users/UserDetailEvents";
import UserDetailLists from "./users/UserDetailLists";
import UserDetailSubscriptions from "./users/UserDetailSubscriptions";
import CampaignDetail from "./campaign/CampaignDetail";
import Campaigns from "./campaign/Campaigns";
import CampaignDelivery from "./campaign/CampaignDelivery";
import CampaignPreview from "./campaign/CampaignPreview";
import CampaignOverview from "./campaign/CampaignOverview";
import CampaignDesign from "./campaign/CampaignDesign";
import Journeys from "./automation/Journeys";
import JourneyEditor from "./automation/JourneyEditor";
import LocationSettings from "./settings/LocationSettings";
import Integrations from "./settings/Integrations";
import Tags from "./settings/Tags";
import Login from "./auth/Login";
import OnboardingStart from "./auth/OnboardingStart";
import Onboarding from "./auth/Onboarding";
import OnboardingLocation from "./auth/OnboardingLocation";
import { ComingSoonPage } from "./ErrorPage";
import Dashboard from "./dashboard/Dashboard";
import {
  CampaignsIcon,
  JourneysIcon,
  ListsIcon,
  SettingsIcon,
  UsersIcon,
  ChatIcon,
  DashboardIcon,
  PosIcon,
  SegmentationIcon,
  InsightsIcon,
} from "../ui/icons";
import { Locations } from "./location/Locations";
import { getRecentLocations, pushRecentLocation } from "../utils";
import Performance from "./organization/Performance";
import Settings from "./settings/Settings";
import LocationSidebar from "./location/LocationSidebar";
import Admins from "./organization/Admins";
import OrganizationSettings from "./organization/Settings";
import Locales from "./settings/Locales";
import JourneyUserEntrances from "./automation/JourneyUserEntrances";
import UserDetailJourneys from "./users/UserDetailJourneys";
import EntranceDetails from "./automation/EntranceDetails";
import { Translation } from "react-i18next";
import Organization from "./organization/Organization";
import ChatPage from "./chat/ChatPage";
import POSDataPage from "./pos/POSDataPage";
import { CartProvider } from "../ChatWidget/CartContext";
import ChatWidget from "../ChatWidget";
import AdvancedSettings from "./settings/AdvancedSettings";
import Insights from "./insights/Insights";
export const useRoute = (includeLocation = true) => {
  const { locationId = "" } = useParams();
  const navigate = useNavigate();
  const parts: string[] = [];
  if (includeLocation) {
    parts.push("locations", locationId);
  }
  return (path: string) => {
    parts.push(path);
    navigate("/" + parts.join("/"));
  };
};

export interface RouterProps {
  routes?: (routes: RouteObject[]) => RouteObject[];
  locationSidebarLinks?: <T extends SidebarLink>(links: T[]) => T[];
  orgSidebarLinks?: <T extends SidebarLink>(links: T[]) => T[];
}

export const createRouter = ({
  routes = (routes) => routes,
  locationSidebarLinks = (links) => links,
  orgSidebarLinks = (links) => links,
}: RouterProps) =>
  createBrowserRouter(
    routes([
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/widget/:customerID",
        element: (
          <CartProvider>
            <ChatWidget skipVerify />
          </CartProvider>
        ),
      },
      {
        path: "*",
        errorElement: <ErrorPage />,
        loader: async () => await api.profile.get(),
        element: (
          <LoaderContextProvider context={AdminContext}>
            <Outlet />
          </LoaderContextProvider>
        ),
        children: [
          {
            index: true,
            loader: async () => {
              const recents = getRecentLocations();
              if (recents.length) {
                return redirect(`locations/${recents[0].id}`);
              }
              return redirect("organization");
            },
            element: <Locations />,
          },
          {
            path: "onboarding",
            element: <Onboarding />,
            children: [
              {
                index: true,
                element: <OnboardingStart />,
              },
              {
                path: "location",
                element: <OnboardingLocation />,
              },
            ],
          },
          {
            path: "organization",
            loader: async () => await api.organizations.get(),
            element: <Organization filter={orgSidebarLinks} />,
            children: [
              {
                index: true,
                loader: async () => {
                  return redirect("locations");
                },
              },
              {
                path: "locations",
                element: <Locations />,
              },
              {
                path: "admins",
                element: <Admins />,
              },
              {
                path: "performance",
                element: <Performance />,
              },
              {
                path: "settings",
                element: <OrganizationSettings />,
              },
            ],
          },
          {
            path: "locations/:locationId",
            loader: async ({ params: { locationId = "" } }) => {
              const location = await api.locations.get(locationId);
              pushRecentLocation(location.id);
              return location;
            },
            element: (
              <StatefulLoaderContextProvider context={LocationContext}>
                <LocationSidebar
                  links={locationSidebarLinks([
                    {
                      key: "dashboard",
                      to: "dashboard",
                      children: (
                        <Translation>{(t) => t("dashboard")}</Translation>
                      ),
                      icon: <DashboardIcon />,
                    },
                    {
                      key: "chat",
                      to: "chat",
                      children: <Translation>{(t) => t("chat")}</Translation>,
                      icon: <ChatIcon />,
                    },
                    {
                      key: "campaigns",
                      to: "campaigns",
                      children: (
                        <Translation>{(t) => t("campaigns")}</Translation>
                      ),
                      icon: <CampaignsIcon />,
                      minRole: "editor",
                    },
                    {
                      key: "journeys",
                      to: "automations",
                      children: (
                        <Translation>{(t) => t("automations")}</Translation>
                      ),
                      icon: <JourneysIcon />,
                      minRole: "editor",
                    },
                    {
                      key: "sales-data",
                      to: "sales-data",
                      children: (
                        <Translation>{(t) => t("sales_data")}</Translation>
                      ),
                      icon: <PosIcon />,
                    },
                    {
                      key: "users",
                      to: "users",
                      children: (
                        <Translation>{(t) => t("customers")}</Translation>
                      ),
                      icon: <UsersIcon />,
                    },
                    {
                      key: "lists",
                      to: "lists",
                      children: (
                        <Translation>
                          {(t) => t("customer_segments")}
                        </Translation>
                      ),
                      icon: <SegmentationIcon />,
                    },
                    {
                      key: "insights",
                      to: "insights",
                      children: (
                        <Translation>{(t) => t("ai_insights")}</Translation>
                      ),
                      icon: <InsightsIcon />,
                    },

                    {
                      key: "settings",
                      to: "settings",
                      children: (
                        <Translation>{(t) => t("settings")}</Translation>
                      ),
                      icon: <SettingsIcon />,
                      minRole: "admin",
                    },
                  ])}
                >
                  <Outlet />
                </LocationSidebar>
              </StatefulLoaderContextProvider>
            ),
            children: [
              {
                index: true, //default page
                loader: async () => {
                  return redirect("dashboard");
                },
              },
              createStatefulRoute({
                path: "dashboard",
                apiPath: api.campaigns,
                element: <Dashboard />,
              }),
              createStatefulRoute({
                path: "campaigns",
                apiPath: api.campaigns,
                element: <Campaigns />,
              }),
              createStatefulRoute({
                path: "campaigns/:entityId",
                apiPath: api.campaigns,
                context: CampaignContext,
                element: <CampaignDetail />,
                children: [
                  {
                    index: true,
                    element: <CampaignOverview />,
                  },
                  {
                    path: "design",
                    element: <CampaignDesign />,
                  },
                  {
                    path: "delivery",
                    element: <CampaignDelivery />,
                  },
                  {
                    path: "preview",
                    element: <CampaignPreview />,
                  },
                ],
              }),
              createStatefulRoute({
                path: "campaigns/:entityId/editor",
                apiPath: api.campaigns,
                context: CampaignContext,
                element: <EmailEditor />,
              }),
              createStatefulRoute({
                path: "automations",
                apiPath: api.automations,
                element: <Journeys />,
              }),

              createStatefulRoute({
                path: "sales-data",
                apiPath: api.campaigns,
                element: <POSDataPage />,
              }),

              createStatefulRoute({
                path: "chat",
                apiPath: api.campaigns,
                element: <ChatPage />,
              }),

              createStatefulRoute({
                path: "insights",
                apiPath: api.campaigns,
                element: <Insights />,
              }),

              createStatefulRoute({
                path: "automations/:entityId",
                apiPath: api.automations,
                context: JourneyContext,
                element: <JourneyEditor />,
                children: [
                  {
                    index: true,
                    element: <JourneyEditor />,
                  },
                  {
                    path: "entrances",
                    element: <JourneyUserEntrances />,
                  },
                ],
              }),
              createStatefulRoute({
                path: "users",
                apiPath: api.users,
                element: <Users />,
              }),
              createStatefulRoute({
                path: "users/:entityId",
                apiPath: api.users,
                context: UserContext,
                element: <UserDetail />,
                children: [
                  {
                    index: true,
                    element: <UserDetailAttrs />,
                  },
                  {
                    path: "events",
                    element: <UserDetailEvents />,
                  },
                  {
                    path: "lists",
                    element: <UserDetailLists />,
                  },
                  {
                    path: "subscriptions",
                    element: <UserDetailSubscriptions />,
                  },
                  {
                    path: "automations",
                    element: <UserDetailJourneys />,
                  },
                ],
              }),
              createStatefulRoute({
                path: "lists",
                apiPath: api.lists,
                element: <Lists />,
              }),
              createStatefulRoute({
                path: "lists/:entityId",
                apiPath: api.lists,
                context: ListContext,
                element: <ListDetail />,
              }),
              {
                path: "entrances/:entranceId",
                loader: async ({ params }: any) =>
                  await api.automations.entrances.log(
                    params.locationId!,
                    params.entranceId!
                  ),
                element: <EntranceDetails />,
              },
              {
                path: "settings",
                element: <Settings />,
                children: [
                  {
                    index: true,
                    element: <LocationSettings />,
                  },
                  {
                    path: "team",
                    element: <Teams />,
                  },
                  {
                    path: "locales",
                    element: <Locales />,
                  },
                  {
                    path: "api-keys",
                    element: <ApiKeys />,
                  },
                  {
                    path: "integrations",
                    element: <Integrations />,
                  },
                  {
                    path: "subscriptions",
                    element: <Subscriptions />,
                  },
                  {
                    path: "tags",
                    element: <Tags />,
                  },
                  {
                    path: "performance",
                    element: <Performance />,
                  },
                  {
                    path: "advanced",
                    element: <AdvancedSettings />,
                  },
                ],
              },
            ],
          },
          {
            path: "*",
            element: <ErrorPage status={404} />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ])
  );
